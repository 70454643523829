app-sidebar {
  .sidebar {
    background-color: $white;
    border: none;

    -webkit-box-shadow: 4px 4px 8px 0px rgba(140, 117, 92, 1);
    -moz-box-shadow: 4px 4px 8px 0px rgba(140, 117, 92, 1);
    box-shadow: none;
    /*box-shadow: 4px 4px 8px 0px rgba(140, 117, 92, 1);*/

    &-header {
      background-color: $black-2;
      color: $white;
      text-align: left;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
    }
    &-body {
      background-color: $gray;
    }
    &-footer {
    }

    .top-label {
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
    }
    .label {
      font-size: 1rem;
    }
  }
}
