app-loyalty-payment-list {
  .header {
    text-align: center;
    font-size: 1.25rem;
    font-weight: bold;
  }

  button.text-black {
    color: $black !important;
    text-decoration: underline;
  }

  button.btn-card {
    border-color: $black !important;
    text-decoration: none;

    i {
      color: $black !important;
    }
  }
}
