app-order-value-summary {
  .order-value-summary-tickets-container {
    color: white;
  }

  .order-value-summary-row {
    background: $primary-color;
    color: white;
    padding: 20px 10px;
    font-size: large;
  }

  .order-value-summary-container {
  }
}
