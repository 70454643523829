$blue: #00aeef;
$yellow: #ffb100;
$gray: #a6a6a6;
$white: #ffffff;
$black: #000000;
$red: #ff0000;
$light-gray: #c1c1c1;
$dark-gray: #39404b;
$orange: #eb991e;
$dark: #191f29;
$gold: #8c755c;

$black-2: #1e1e1e;
$black-3: #d9d9d9;
$gray: #f3f4f6;
$gray-2: #9ca3af;
$gray-3: #7f7f7f;

$error-color: #d7403a;

$font-size-base: 0.813rem;
$font-family-base: "Inter";
$font-family-heading: "TT Nooks";
$font-family-alt: "Inter";

$primary-color: $gold;
$default-color: $gold;
$default-color-text: $black;
$secondary-color: $white;

$background-header: $black-2;
$background-color: $white;
$background-footer: $black;

$seats-columns: 60;
$seats-column-size: 25;
$seats-column-margin: 8;
$seats-column-margin-bottom: 8px;
$seats-row-size: 24;

$background-icon-free: transparent;
$background-icon-mine: transparent;
$background-icon-occupied: transparent;

$table-border-color: #3e3e3e;

// bootstrap -> card
$card-bg: transparent;
$card-border-width: 0;
$carousel-control-color: #17c3de;

$footer-height: 30px;

$interaction-gray: #cfd5d9;
$dark-interaction-gray-2-hover: #cfd5d9;
$dark-interaction-gray-2: #b1b9be;
$dark-interaction-gray-2-color: #141414;
$dark-interaction-gray-hover: #cfd5d9;
$dark-interaction-gray: #b1b9be;

$google-pay-button-background: #ffffff;
$google-pay-button-background-hover: #eff7f8;
$google-pay-button-border: #ffffff;
$google-pay-button-border-hover: #ffffff;
$google-pay-button-color: #141414;

$apple-pay-button-background: #050707;
$apple-pay-button-background-hover: #4f555b;
$apple-pay-button-border: #050707;
$apple-pay-button-border-hover: #4f555b;
$apple-pay-button-color: #ffffff;

$btn-transparent: transparent;
$interaction-blue: #086adb;
$dark-interaction-blue-2-hover: #147df5;
$dark-interaction-blue-2: #0a71e9;
$dark-interaction-blue-2-color: #ffffff;
$dark-interaction-blue-hover: #5ca3ff;
$dark-interaction-blue: #4294ff;
$special-grid-columns: 16;
$voucher-color: #6dd55a;

$defaultborder: 6px solid $primary-color;

$poster-border-radius: 6px;
