app-order-tickets-summary {
  .order-tickets-summary-block {
    width: 100%;

    .order-tickets-summary-block-head {
      color: $gray-3;
      font-size: 10px;
    }

    .text-gray {
      color: #666666;
    }

    .b1 {
      font-size: 12px;
    }

    .increment {
      position: relative;
      bottom: 1px;
      padding: 0.1rem 0.2rem;

      i {
        cursor: pointer;
      }
    }

    .fb-item-description {
      font-size: 0.85rem;
    }
  }

  .to-pay {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600 !important;
  }

  .block {
    border-bottom: none;
    padding: 0px 0px;
    margin: 0px 0px;
    &:first-of-type {
      padding: 0px;
      margin: 0px;
    }
    &:last-of-type {
      border-bottom: none;
    }

    &.block-fee {
      background: #ffffff;
      border-radius: 2rem;
      display: block;
      border: 0 none;
    }

    .increment {
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currentlysupported by Chrome and Opera */
      cursor: pointer;
      background: #05c3de;
      margin: 0px 3px;
      border-radius: 50%;
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      line-height: 1.5rem;
      text-align: center;
      font-size: 12px;
      color: #fff;
    }
  }

  h4 {
    padding-bottom: 0.5rem;
  }

  .fb-item-description {
    font-size: 0.7rem;
    color: $gray-600;
  }

  .fb-summary-cell {
    padding: 0 !important;
    padding-left: 0.75rem !important;
  }

  .table {
    tr {
      td {
        border: 0 none;
        width: 33%;

        span.help-block {
          display: block;
          color: $gray;
        }
      }
    }
  }
  .info {
    color: $gray;
    padding: 0px 15px;
  }

  .total-price-info {
    font-size: 0.7rem;
    display: block;
    color: gray;
  }
}
