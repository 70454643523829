app-popup {
  z-index: 100;

  .fade-in {
    opacity: 1;
    animation-name: appPopupFadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.4s;
  }

  .fade-out {
    opacity: 0;
    animation-name: appPopupFadeOutOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.4s;
  }

  @keyframes appPopupFadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes appPopupFadeOutOpacity {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1003;
    display: flex;
    justify-content: center;
    align-items: center;

    .popup {
      border: 1px solid $gold;
      border-radius: 0;
      box-shadow: none;
      background: $white;
      text-align: center;
      color: $black;
      width: 34rem;

      -webkit-box-shadow: 4px 4px 8px 0px rgba(140, 117, 92, 1);
      -moz-box-shadow: 4px 4px 8px 0px rgba(140, 117, 92, 1);
      box-shadow: 4px 4px 8px 0px rgba(140, 117, 92, 1);

      .header {
        padding: 1rem;
        border-bottom: 1px solid $gold;

        h4 {
          padding: 0;
        }

        .close {
          display: none;
          position: relative;
          right: 5px;
          top: 30px;
          cursor: pointer;
          background-image: url("/assets/images/close2.svg");
          width: 20px;
          height: 20px;
          background-size: cover;
          margin-right: 1rem;
        }
      }

      .body {
        padding: 1rem;
      }

      .body,
      .footer {
        display: block;

        button.btn-next {
          margin: 0 0.5rem 0.5rem 0.5rem;
        }
      }

      .footer {
        padding: 1rem;

        button {
          margin: 0 !important;
        }
      }
    }
  }
}

modal-container {
  padding: 1rem;

  .modal-dialog {
    height: 100%;
    display: flex;
    align-items: center;
    margin: auto auto;
  }

  .modal-body {
    border: 2px solid $gold;
    background-color: $white;
    color: $black;
  }
}

.modal-header {
  .close {
    margin: 0px;
    padding: 5px;
  }
}

.modal {
  .close {
    content: "";
    position: absolute;
    right: 2px;
    top: 3px;
    cursor: pointer;
    background-size: cover;
    opacity: 0.8;
  }

  .close:not(:disabled):not(.disabled):hover {
    opacity: 1;
  }
}
