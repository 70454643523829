app-catering-article-popup {
  .popup-area {
    .header {
      .title {
        font-weight: bold;
        font-size: 1.6rem;
      }
    }

    .separator {
      border-bottom: 1px solid black;
      height: 1px;
    }

    .modifier-list {
      .info-label {
        font-weight: bold;
        text-transform: uppercase;
      }

      .article-area {
        max-height: 34vh;
        overflow-y: auto;

        scrollbar-color: gray transparent;
        scrollbar-width: thin;

        -webkit-box-shadow: inset 0px 0px 15px 5px rgba(255, 255, 255, 1);
        -moz-box-shadow: inset 0px 0px 15px 5px rgba(255, 255, 255, 1);
        box-shadow: inset 0px 0px 15px 5px rgba(255, 255, 255, 1);

        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f5;
        }

        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $black;
          border-radius: 30px;
        }

        .modifier-area {
          .modifier-item-required-label {
            font-size: 0.8rem;
            color: gray;
          }

          .modifier-name {
          }

          .modifier-item-name {
            margin-top: -8px;
            font-size: 13px;
          }

          .modifier-item-price {
            font-size: 13px;
            text-align: right;
          }
        }
      }
    }

    height: 100% !important;

    .footer {
      .cancel-button-area {
        display: flex;
      }

      .add-button-area {
        display: flex;
        justify-content: end;
      }

      .cancel-button,
      .add-button {
        height: 2rem;
        width: 8rem;
        border: 1px solid gray;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        user-select: none;

        &:hover {
          cursor: pointer;
        }

        .label {
          padding-top: 0.15rem;
        }
      }

      .cancel-button {
        background-color: white;
        color: gray;

        &:hover {
          background-color: gray;
          color: white;
        }
      }

      .add-button {
        background-color: gray;
        color: white;

        &:hover {
          background-color: #720d1e;
        }
      }
    }
  }
}
