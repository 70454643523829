app-tabs {
  ul {
    row-gap: 1rem;
    border-bottom: $defaultborder;
    border-top: $defaultborder;

    li {
      cursor: pointer;
      padding: 1rem;
      float: left;

      &.active {
        background-color: $primary-color;
      }

      @media (max-width: 576px) {
        flex-grow: 1;
      }
    }
  }

  app-tab {
    > div.content {
      padding: 1rem;
    }
  }
}
