mv-slider {
  .slider-tick {
    display: none;
  }

  .slider.slider-horizontal {
    width: 100%;
  }

  .slider-tick-label {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.1px;
    color: $white;
  }

  .slider-tick-label:first-of-type {
    padding-left: 4.9rem;
  }

  .slider-tick-label:last-of-type {
    padding-right: 5.6rem;
  }

  .slider-selection {
    background-image: linear-gradient(to bottom, $primary-color 0, $primary-color 100%);
  }

  .slider-handle {
    width: 20px;
    height: 20px;
    background-color: $primary-color;
  }
}
