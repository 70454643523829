app-voucher-card-summary {
  > div {
    width: 100%;

    .voucher-title {
      color: $primary-color;
      font-size: 1.1rem;
      font-weight: bold;
    }

    .voucher-applied-label {
      font-weight: bold;
    }
  }
}
