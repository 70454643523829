.kinoteka-datepicker {
  background: #222222;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);

  /* button */
  button:hover,
  button:focus,
  button:active,
  input:hover,
  input:focus,
  input:active,
  &-btns button:hover,
  &-btns button:focus,
  &-btns button:active,
  &-predefined-btns button:active,
  &-predefined-btns button:focus {
    outline: none;
  }

  /* .bs-datepicker-head */
  .bs-datepicker-head {
    white-space: nowrap;
    min-width: 270px;
    height: 50px;
    padding: 0;
    border-radius: 3px 3px 0 0;
    text-align: justify;

    &:after {
      content: "";
      display: inline-block;
      vertical-align: top;
      width: 100%;
    }

    bs-datepicker-navigation-view {
      text-align: center;
      display: flex;
    }

    /* .bs-datepicker-head button */
    button {
      display: inline-block;
      vertical-align: top;
      padding: 0;
      height: 30px;
      line-height: 30px;
      border: 0;
      background: transparent;
      text-align: center;
      cursor: pointer;
      color: $font-color-01;
      transition: 0.3s;
      margin: 10px auto;

      &:hover {
        background-color: transparent;
      }

      &[disabled],
      &[disabled]:hover,
      &[disabled]:active {
        background: rgba(221, 221, 221, 0.3);
        color: #adadad !important;
        cursor: not-allowed;
      }

      &.next,
      &.previous {
        border-radius: 50%;
        width: 30px;
        min-width: 30px;
        height: 30px;
        font-weight: 200;
        color: $white;

        span {
          font-size: 48px;
          line-height: 26px;
          display: inline-block;
          position: relative;
          height: 100%;
          width: 100%;
          border-radius: 50%;
        }
      }

      &.current {
        border-radius: 15px;
        //max-width: 155px;
        width: 100%;
        padding: 0 13px;
      }
    }
  }

  .bs-datepicker-container {
    padding: 0px;
    background-color: #242424;

    &:after {
      display: block;
      content: "";
      position: absolute;
      top: -12px;
      left: 12px;
      width: 0;
      height: 0;
      z-index: 9999;
      border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #1f1f1f;
      border-style: solid;
      border-width: 0 12px 12px;
    }

    .bs-datepicker-head {
      white-space: nowrap;
      background-color: #242424;
      font-weight: 500;
      font-size: 24px;
      line-height: 1.33;
      letter-spacing: -0.2px;

      min-width: 392px;
    }

    .bs-datepicker-body {
      padding: 0;
      border-radius: 0;
      border: none;

      min-height: 200px;
      min-width: 392px;

      span {
        border: 1px solid transparent;
        font-size: 18px;
        font-weight: 600;
        margin: 0 5px;
        border-radius: 0;
        transition: border-color 0.15s ease-in-out, background-color 0.15s ease-in-out;
      }

      .years span {
      }

      .months span {
      }

      .days span {
        width: 46px;
        height: 46px;
        line-height: 46px;
      }

      table {
        th {
          padding-bottom: 16px;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.57;
          letter-spacing: 0.1px;
        }

        tbody {
          background-color: #1d1d1d;

          td {
            span {
              color: #e6e6e6;

              &.today {
                border-color: #adadad;
              }
            }
          }

          td.is-other-month span,
          span.is-other-month,
          td.disabled span,
          span.disabled {
            color: #adadad;
          }

          td.is-highlighted:not(.disabled):not(.selected) span,
          span.is-highlighted:not(.disabled):not(.selected) {
            background-color: $white;
            border: solid 1px $white;
            color: $black;
          }

          td span.selected,
          td.selected span,
          span[class*="select-"]:after,
          td[class*="select-"] span:after {
            background-color: $white;
          }
        }
      }
    }
  }
}
