app-book-movie-list {
  display: block;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  color: $white;
  background-color: $black-2;

  .movie-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 2rem;

    .movie-poster {
      text-align: center;
      width: 100%;

      img {
        max-height: 180px;
      }
    }

    .movie-title {
      text-align: center;
      padding: 1rem 0;

      font-size: 16px;
      letter-spacing: -0.8px;
    }

    .screen-info-1 {
      padding: 0 2.5rem 1rem 2.5rem;

      .movie-info-label {
        color: $gray-3;
        font-feature-settings: "clig" off, "liga" off;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
      }

      .movie-info-val {
        color: $white;
        font-feature-settings: "clig" off, "liga" off;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }

  .book {
    &-movie {
      color: $white;

      &-head {
        margin: 0 0 10px;
      }
      &-experience {
        padding: 10px;
        margin: 10px 0;
      }
      &-image {
        position: relative;
        height: 100px;
        img {
          display: block;
          margin: 0 auto;
          height: 100% !important;
        }
      }
      &-info {
        padding: 20px 10px;
      }
      &-extend {
        padding-left: 1rem;
      }
      &-extend {
        &-label {
          font-size: 15px;
        }
        &-field {
          color: #666;
        }
      }
      &-title {
        text-align: left;
        text-transform: uppercase;
        font-size: 20px;
      }
      &-list {
        &-row {
          padding: 10px 0 0;
          margin: 10px 0 0;
          &:first-child {
            margin-top: 0;
          }
          &:before,
          &:after {
            content: " ";
            display: table;
          }
          &:after {
            clear: both;
          }
        }
        &-label {
          float: left;
          width: 40%;
          text-transform: uppercase;
          font-size: 16px;
        }
        &-field {
          float: right;
          text-align: right;
          width: 60%;
          word-wrap: break-word;
        }
      }

      &-cost {
        padding: 10px 10px 20px;
        background: $primary-color;
        color: $white;
        display: none;
        &-row {
          margin: 10px 0 0;
          &:before,
          &:after {
            content: " ";
            display: table;
          }
          &:after {
            clear: both;
          }
        }
        &-label {
          float: left;
          width: 60%;
          text-transform: uppercase;
          font-size: 16px;
        }
        &-field {
          float: right;
          text-align: right;
          width: 40%;
        }
      }
    }
    &-confirm-image {
      display: none;
    }
  }
  .movie-info-footer {
    height: 90px;
    background-color: $black-2;
    display: block;

    .concave {
      height: 24px;
      width: 100%;
      background-image: url("/assets/images/screen/ticket-line.svg");
      background-position: 50% 50%;
      background-size: 80%;
      background-repeat: no-repeat;

      .l,
      .r {
        width: 70%;
        height: 100%;
        background: $white;
        border-radius: 24px;
      }
      .l {
        margin-left: -65%;
        float: left;
      }
      .r {
        margin-right: -65%;
        float: right;
      }
    }
    .movie-info-footer-barcode {
      height: 65px;
      width: 100%;
      padding: 1rem;
      .movie-info-footer-barcode-image {
        background-image: url("/assets/images/screen/barcode.svg");
        background-position: 50% 50%;
        background-size: 75%;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
      }
    }
  }
}
