app-screen-seats-selected-list {
  background-color: $dark;
  .seats-selected-list-container {
    span {
      display: inline-block;
    }
    span.seat:after {
      content: ',\00a0';
    }
    span.seat:last-child:after {
      content: '';
    }
  }

  .seat-container {
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
    background-image: url('/assets/images/screen/seats-line.svg');
    background-position: 0 100%;
    background-repeat: repeat-x;

    .seat-container-header {
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 12px;
      padding-bottom: 12px;
    }

    .row-head {
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      color: $gray-3;
    }

    .row-body {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      color: $black-2;
    }

    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      background-image: none;
      padding-bottom: 0;
    }
  }
}
