app-cart-popup-cart-component {
  .dropdown-menu {
    border-top-right-radius: 0 !important;
    width: 450px !important;
    position: absolute !important;
    top: 36px;
    left: calc(100% - 450px);

    .cart-header {
      width: 100%;
      margin-top: -20px;

      .strike {
        &:hover {
          opacity: 0.8;
          cursor: pointer;
        }
      }
    }
  }

  .checkout-button {
    width: 100%;
  }

  .cart-container {
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;

    .message-block {
    }
  }

  .item-container {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gold;
      border-radius: 30px;
    }

    scrollbar-color: $gold transparent;
    scrollbar-width: thin;

    overflow-y: hidden;
    overflow-x: auto;

    max-height: 100px;
  }
}

[dir="rtl"] {
  app-cart-popup-cart-component {
    .dropdown-menu {
      border-top-right-radius: 0 !important;
      width: 450px !important;
      position: absolute !important;
      top: 36px;
      left: calc(0% + 0px);
    }
  }
}
