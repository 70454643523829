app-page-screen {
  display: flex;
  flex-flow: column;
  height: 100%;
  flex: 1 0 auto;

  .main-screen-container {
    width: 100%;
    padding: 1.5rem 0.5rem;

    .chosen-col {
      padding: 0 1rem;

      .sidebar {
        box-shadow: none;
        border: none;

        .chosen-sidebar-header {
          font-size: 14px;
          font-weight: 500;
        }

        .sidebar-header,
        .sidebar-body {
          padding: 16px 22px 16px 16px !important;
        }

        .sidebar-body {
          max-height: 480px;
          overflow: auto;
        }

        .chosen-sidebar-body {
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }

  .container {
    flex: 1 0 auto;
    border-bottom: 6px solid $white;
  }

  h4 {
    padding-bottom: 2rem;
  }
  .space {
    padding-top: 72px;
  }
  .screen-header {
    text-align: center;
    color: $black;
    padding: 16px 9px 9px;
    margin: 0 auto;
    font-size: 14px;
    font-weight: 500;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
  }
  .col-summary {
    transform: rotate(-360deg);
    .col-summary-close {
      display: none;
    }
  }
  .screen-swapping-popup {
    text-align: center;
    display: flex;
    flex-direction: column;
    min-height: 20rem;
    .popup-close-button {
      margin-top: auto;
    }
  }

  .screen-header-container {
    background-image: url(/assets/images/screen/ekran-2.svg);
    background-position: top;
    background-repeat: no-repeat;
    height: 60px;
    background-size: contain;
    position: relative;
  }

  font-family: $font-family-alt;
  span {
    font-family: $font-family-alt;
  }

  .screen-reflections::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 80%, rgba(255, 255, 255, 0.9) 100%);
    opacity: 0.2;
    animation: flicker 1s infinite alternate;
    z-index: 1;
  }

  @keyframes flicker {
    from {
      opacity: 0.2;
    }
    to {
      opacity: 0.6;
    }
  }

  @media (max-width: 992px) {
    .container {
      max-width: 100%;
    }
  }
}

pinch-zoom {
  .pz-zoom-button.pz-zoom-control-position-bottom {
    left: 90% !important;
  }
}

[dir="ltr"] {
  app-page-screen {
    .strike-desc {
      display: block;
      overflow: hidden;
      padding-left: 30px;
      font-size: 0.9rem;
      > span {
        position: relative;
        display: inline-block;
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          width: 99px;
          height: 1px;
          background: $primary-color;
        }
        &:before {
          right: 100%;
          margin-right: 15px;
        }
      }
    }
  }
}
