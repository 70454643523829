div.pimg {
  display: flex !important;
  justify-content: center !important;
}

.pimg-container {
  position: relative;
  display: inline-flex;

  &-picture {
    aspect-ratio: 3 / 2;
  }

  &-poster {
    aspect-ratio: 2 / 3;

    img[pimg] {
      border-radius: $poster-border-radius;
    }
  }

  &-card {
    aspect-ratio: 3 / 2;
  }

  &-square {
    aspect-ratio: 1 / 1;
  }

  img[pimg] {
    height: 100%;
    width: 100%;
    object-fit: contain;

    &.rounded {
      border-radius: 5px !important;
    }

    &.clickable {
      cursor: pointer;
    }

    &.square {
      aspect-ratio: 1 / 1 !important;
    }
  }
}
