app-user-login {
  .form-control,
  .form-control:focus {
    border: 1px solid #b6b6b6;
    border-radius: 2rem;
    background-color: transparent;
    padding: 1.8rem 2rem;
  }

  .next-info {
    background-color: #dff7fb;
    margin: 0px -120px;
    padding: 20px 120px;
    .btn {
      border: 1px solid #b6b6b6;
      background: transparent;
      border-radius: 2rem;
      padding: 14px 35px;
      span {
        font-weight: bold;
        color: $gold;
        text-decoration: underline;
      }
    }
  }

  .register-button {
    color: #004b87;
    text-decoration: underline;
    font-weight: bold;
  }

  .card-footer {
    padding: 0;
  }

  .card-body {
    padding: 0;
  }

  .card-header {
    padding: 20px 0px;
  }

  h3 {
    color: #4a4a4a;
    font-weight: bold;
  }

  .btn-primary {
    border-radius: 1.5rem 0.6rem 0.6rem 0.6rem;
    background: linear-gradient(to right, #05c3de, #004b87);
    padding: 0.3rem 2rem;
    border: 0;
    border-top: 0;
    font-size: 1.5rem;
  }
}
