select.form-control {
  padding: 0rem 0.375rem;
  border-radius: 0;
}

.with-errors {
  color: $red;
  font-size: 11px;
}

.form-group {
  &.required {
    label:after {
      content: "*";
      padding-left: 1px;
    }
  }
  .with-errors {
    color: $red;
  }
}

.form-control,
.form-control:focus {
  font-family: $font-family-base;
  border: 1px solid #ced4da;
  border-radius: 0px;
  background-color: $white;
  padding: 6px 15px !important;
  box-sizing: border-box;
  font-size: 1rem;
  line-height: 1.063rem;
  height: 2.5rem;
  color: $black;

  &.form-control-bigger {
    font-size: 1.5rem;
  }

  &:disabled {
    background-color: $dark-gray;
    border-color: $dark-gray;
    color: $white;
  }
}

.form-control-checkbox,
.form-control-checkbox:focus {
  height: 1rem;
  width: 15px;
}

.form-control-description {
  font-weight: bold;
  font-size: small;
}

.form-control-title {
  font-size: small;
}

.input-group-append-right {
  border-radius: 2rem;
  border-right: 0 none !important;
  border-right: none;
}

.input-group-append-left {
  border-top-right-radius: 2em !important;
  border-bottom-right-radius: 2em !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.input-group-button-right {
  border: 1px solid #b6b6b6;
  background-color: transparent;
  border-top-right-radius: 2em !important;
  border-bottom-right-radius: 2em !important;
  padding: 1.1rem 2rem;
  border-left: 0 none !important;
  color: #b6b6b6;
  font-size: 21px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
}

.input-group-button-left {
  border: 1px solid #b6b6b6;
  background-color: transparent;
  border-top-left-radius: 2em !important;
  border-bottom-left-radius: 2em !important;
  padding: 0.5rem 0.2rem;
  border-right: 0 none !important;
  color: #b6b6b6;
  font-size: 21px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
}

.show-hide-password-input {
  border-right: 0 none !important;
  border-right: none;
}

.show-hide-password-button {
  border: 1px solid #b6b6b6;
  background-color: transparent;
  border-top-right-radius: 2em !important;
  border-bottom-right-radius: 2em !important;
  padding: 1.1rem 2rem;
  border-left: 0 none !important;
  color: #b6b6b6;
  font-size: 21px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
}

.btn-primary,
.btn-default,
.btn-outline-primary,
.btn-next {
  border-radius: 0;
  padding: 0.6rem 2rem;
  line-height: 18px;
  min-width: 80px;
  box-shadow: none;
  font-size: 14px;
  font-weight: 500;
}

.btn-screen-navigation {
  padding: 24px 10px;
  line-height: 18px;
  font-size: 14px;
  font-weight: 500;
}

.btn-back {
  transition: opacity 0.3s ease; // Płynne przejście opacity

  &:before {
    content: "";
    color: black;
    background: url("/assets/images/arrow_back.svg") no-repeat;
    background-size: contain;
    width: 16px; // Ustaw szerokość obrazu
    height: 16px; // Ustaw wysokość obrazu
    display: inline-block;
    position: absolute;
    left: 20%;
    opacity: 0; // Początkowa przezroczystość
    transition: opacity 0.3s ease; // Płynne przejście opacity
  }

  &:hover {
    opacity: 0.8; // Przy najechaniu zmienia opacity
    &:before {
      opacity: 1; // Po najechaniu na przycisk, chevron staje się widoczny
    }
  }
}

@media (max-width: 766px) {
  .btn-primary,
  .btn-default,
  .btn-outline-primary,
  .btn-next {
    min-width: 80px;
  }
}

.btn {
  color: $white;

  &:hover {
    color: $white;
  }

  &:disabled {
    background-color: #dadada;
    &:hover {
      cursor: not-allowed;
    }
  }

  &-primary {
    background-color: $primary-color;
    border: 1px solid $primary-color;
    &:hover,
    &:active {
      border: 1px solid $primary-color !important;
    }

    &:disabled {
      background-color: $gray !important;
      border: 1px solid $primary-color !important;
      &:hover {
        cursor: default;
      }
    }
  }

  &-default {
    background-color: $gray !important;
    border: 1px solid $gray;
    color: $black-2;

    &:hover,
    &:active {
      border: 1px solid $gray-2 !important;
      color: $black-2;
    }

    &:disabled {
      background-color: $gray-2 !important;
      border: 1px solid $gray-2 !important;
      color: $white;
      &:hover {
        cursor: default;
      }
    }
  }

  &-next {
    color: $white;
    background-color: $black-2 !important;
    border: 1px solid $black-2 !important;

    &:hover,
    &:active {
      border: 1px solid $black !important;
    }

    &:disabled {
      opacity: 0.5;
      &:hover {
        cursor: default;
      }
    }
  }

  &-outline-primary {
    border-color: $gray;
    color: $gray;
    background-color: white;

    &:hover,
    &:active {
      background-color: $gray !important;
      border-color: $gray !important;
    }
  }
}

.checkbox {
  > label {
    display: flex;
    flex-direction: row;
    margin-bottom: 0 !important;
    &:hover {
      cursor: pointer;
    }
  }
  .label {
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }
  .cr {
    position: relative;
    display: inline-block;
    border: 1px solid $black;
    border-radius: 2px;
    width: 1.7em;
    height: 1.7em;
    margin-right: 1em;
    color: $black;
    min-width: 1.7em;
    .cr-icon {
      position: absolute;
      font-weight: lighter;
      line-height: 0;
      top: 50%;
      left: 15%;
    }
  }
  .label-content {
    line-height: 1.8;
  }
  .label-content-big {
    font-size: 0.8rem;
    margin-top: 2px;
  }
  label input[type="checkbox"] {
    display: none;
  }
  label input[type="checkbox"] + .cr > .cr-icon {
    opacity: 0;
  }
  label input[type="checkbox"]:checked + .cr > .cr-icon {
    opacity: 1;
  }
  label input[type="checkbox"]:disabled + .cr {
    opacity: 0.5;
  }
}

.checkbox-active {
  font-size: bold;
  border-radius: 3px !important;
  color: $black !important;
  background-color: $white;
  i {
    font-size: 0.8rem;
    left: 20% !important;
  }
}

.checkmark {
  position: absolute;
  top: -5px;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid $black;
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}

.radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 1em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  &:hover input:not(:checked) ~ .checkmark:after {
    top: 7px;
    left: 7px;
    width: 6px;
    height: 6px;
    background-color: $black;
  }
  &:hover input:checked ~ .checkmark {
    border-color: $black;

    &:after {
      background-color: $black;
    }
  }
  input:checked ~ .checkmark {
    border-color: $black;
  }
  input:checked ~ .checkmark:after,
  input:hover ~ .checkmark:after {
    display: block;
  }

  input.selected ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    top: 6px;
    left: 6px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $black;
  }
}

input[type="file"] {
  display: none;
}

*[dir="rtl"] {
  .show-hide-password-button {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 2em !important;
    border-bottom-left-radius: 2em !important;
    border: 1px solid #b6b6b6 !important;
    border-right: 0 !important;
  }
  .show-hide-password-input {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 2em !important;
    border-bottom-right-radius: 2em !important;
    border: 1px solid #b6b6b6 !important;
    border-left: 0 !important;
  }

  .input-group-button-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 2em !important;
    border-bottom-left-radius: 2em !important;
    border: 1px solid #b6b6b6 !important;
    border-right: 0 !important;
  }
  .input-group-append-right {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 2em !important;
    border-bottom-right-radius: 2em !important;
    border: 1px solid #b6b6b6 !important;
    border-left: 0 !important;
  }

  .input-group-append-left {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 2em !important;
    border-bottom-left-radius: 2em !important;
  }

  .input-group-button-left {
    border: 1px solid #b6b6b6 !important;
    background-color: transparent;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 2em !important;
    border-bottom-right-radius: 2em !important;
    padding: 0.5rem 0.2rem;
    border-left: 0 none !important;
    border-right-width: 1px !important;
    color: #b6b6b6;
    font-size: 21px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
  }

  .checkbox .cr {
    margin-right: 0 !important;
    margin-left: 0.5em !important;
  }
}

.color-orange .btn-link {
  font-size: 1.1rem;
  color: $orange !important;
}

.color-blue .btn-link {
  color: $primary-color !important;
}

textarea.form-control {
  resize: none;
  height: 100% !important;
}
