app-screening-select-date {
  .date {
    font-size: 15.25px;
    font-family: $font-family-base;
    font-weight: 300;
    color: rgb(64, 63, 63);
    text-align: left;
  }

  .screening-select-date {
    position: relative;
    overflow: hidden;
    text-align: center;
    padding: 0;

    .day {
      color: #000;
      background: #fff;
      cursor: pointer;
      position: relative;

      .border {
        display: none;
      }
      > .date {
        display: inline-block;
        text-transform: uppercase;
        background: #efefef;
        border-right: 1px solid #e1e1e1;
        border-left: 1px solid #efefef;
        border-bottom: 1px solid #efefef;
        border-top: 1px solid #efefef;
        color: #000;
        text-align: center;

        min-width: 75px;
        padding: 8px 0;
        padding: 0.6153846154rem 0;
        border-right: 1px solid #e1e1e1;
        line-height: 1;
        height: 46px;
        height: 3.5384615385rem;
        font-size: 18px;
        font-size: 1.3846153846rem;
        box-sizing: border-box;
        vertical-align: top;
        position: relative;
        transition: all 0.3s;

        > .calendar-date {
          color: #525252;
          font-size: 10px;
          font-weight: 600;
          display: block;
          transition: all 0.3s;
        }
        &.calendar {
          font-size: 2rem;
        }
      }
      &:not(.selected) {
        &:hover > .date {
          background: $primary-color;
          border-right: 1px solid $primary-color;
          border-left: 1px solid $primary-color;
          border-bottom: 1px solid $primary-color;
          border-top: 1px solid $primary-color;
          color: #fff !important;
          cursor: pointer;

          h7,
          .calendar-date {
            color: #fff !important;
          }
        }
      }
      &.selected {
        .date {
          color: $white;
          background: $primary-color;
          border: 1px solid #9f9f9f;
          cursor: default;
          > .calendar-date {
            color: $white;
          }
        }
      }
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    .prev-day-button,
    .next-day-button {
      background-color: transparent;
      border: none;
      color: $black;
      box-shadow: none;
    }
  }
}

@media (max-width: 767px) {
  app-screening-select-date {
    bs-datepicker-container {
      top: 116px !important;
      left: auto !important;
      margin: 0 auto !important;
      transform: none !important;
    }
  }
}
