app-steps {
  .steps-bar {
    background: $gray;
    width: 100%;
    padding: 0.4rem 0;

    &.topTab {
    }

    .topTabLinks {
      width: 100%;
      margin: 0 auto;
      padding: 0;
      list-style: none;
      border-radius: 0;
      display: table;
      table-layout: fixed;
      border-collapse: collapse;
    }

    .step {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 1rem 0;
      padding: 0 10px;
      max-width: calc(25% - 2px) !important;

      color: $secondary-color-text-inactive;
      font-size: 14px;

      text-decoration: none;
      cursor: default;
      pointer-events: none;

      text-align: center;

      height: 48px;
      height: 3.6923076923rem;
      line-height: 1;
      transition: all 0.2s;

      &.current {
        color: $secondary-color-text;
        text-decoration: none;

        .step-number {
          background-color: $black;
        }
      }

      &.completed {
        //border-bottom: 2px solid #C55E7A;
      }

      .step-number {
        width: 26px;
        height: 26px;
        background-color: $gray-2;
        color: $white;
        border-radius: 50%;
        text-align: center;
        line-height: 26px;
        span {
          margin: 0 auto;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  app-steps {
    .steps-bar {
      .step.current {
        background-color: $black;
        color: $white;
      }
    }
  }
}
