app-page-personal {
  .btn {
    min-width: 140px;
  }

  .error-message-box {
    color: $white;
    background-color: $error-color;
    text-align: center;
    h6 {
      margin: 0;
    }
  }

  input::placeholder {
    opacity: 0.5 !important;
  }

  font-family: $font-family-alt;
  span {
    font-family: $font-family-alt;
  }

  .form-control,
  .form-control:focus {
    font-family: $font-family-alt;
  }

  .main-container {
    .movie-preview-container {
      width: 17.5%;
      margin: 0 15px;
    }

    .sidebar-header {
      font-size: 14px;
      font-weight: 500;
    }

    .sidebar-body {
      font-size: 12px;
      font-weight: 400;
    }
  }

  @media (max-width: 992px) {
    .container {
      max-width: 100%;

      .main-container {
        margin: 0;

        @media (min-width: 768px) {
          .basket-container {
            max-width: calc(100vw - 30%);
          }
        }
      }
    }
  }
}
