@media (min-width: 992px) {
  .w-360 {
    min-width: 360px;
  }

  .right-column {
    width: calc(100% - 360px);

    .top-row {
      height: 182px;
    }

    .bottom-row {
      height: 162px;
    }

    &.unlimited {
      .top-row {
        height: 242px;
      }

      .bottom-row {
        height: 111px;
      }
    }
  }
}

app-gift-cards-page {
  .preview {
    img {
      width: 100%;

      &:not(.selected) {
        border-radius: 6px;
      }
    }

    @media (min-width: 430px) {
      img {
        width: 100% !important;
      }
    }
  }

  .value-panel {
    flex-grow: 1;
  }

  .empty-card {
    background-color: $black;
  }
}
