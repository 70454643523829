app-header {
  .container {
    background-color: $background-header;
    position: relative;
    width: 100%;
    max-width: 100%;

    div.head-inner {
      .brand {
        padding: 1rem 0;
      }
    }
  }
}

@media (max-width: 576px) {
  app-header {
    .container {
      .brand {
        width: 50%;
      }
    }
  }
}

.logocontainer {
  @media (max-width: 768px) {
    img {
      height: 60px !important;
    }
  }
}
