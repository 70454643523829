app-countdown,
app-simple-countdown {
  display: block;
  min-height: 24px;

  span.clock-label {
    font-size: 20px;
    font-weight: 700;
    padding-left: 0.5rem;
  }
  span.clock-countdown {
    color: $default-color-text;
    font-size: 14px;
    font-weight: 400;
    border: none !important;
    height: unset !important;
    width: unset !important;
    line-height: 22px !important;
  }
  .countdown-icon {
    font-size: 24px;
  }
}
