app-catering-article-group-container {
  @media (min-width: 768px) {
    .article-block {
      padding-right: 2rem !important;
    }
  }

  .view-container {
    .header {
      .skip-fb-button {
        z-index: 3;
        border-radius: 6px;
        border: 1px solid $gray;
        background-color: transparent;
        color: $white;
        font-weight: bold;
        padding: 0.3rem 0.9rem;
        font-size: 0.7rem;

        &:hover,
        :focus,
        :active {
          background-color: #f8f5fe !important;
          border: 1px solid $gray;
          color: $white;
        }
      }

      .title {
        font-weight: bold;
        font-size: 1rem;

        .info-circle {
          position: relative;
          bottom: 3px;
          border-radius: 50%;
          background-color: $black;
          color: white;
          font-size: 0.9rem;
          text-align: center;
          display: inline-block;
          width: 1.4rem;
          height: 1.4rem;
          user-select: none;

          .tooltip-box {
            z-index: 5;
            display: none;
            position: absolute;
            background-color: white;
            border: 3px solid $white;
            border-radius: 6px;
            color: $black;
            width: 1300%;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      .tip {
        font-size: 1.1rem;
      }
    }

    .group-tab-container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      scrollbar-color: $gray transparent;
      scrollbar-width: thin;

      -webkit-box-shadow: inset 0 0 15px 5px rgba(255, 255, 255, 1);
      -moz-box-shadow: inset 0 0 15px 5px rgba(255, 255, 255, 1);
      box-shadow: inset 0 0 15px 5px rgba(255, 255, 255, 1);

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px $gray;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $gray;
        border-radius: 30px;
      }

      .tab-block {
        display: inline-block;
        width: 11rem;
        user-select: none;

        .tab {
          display: block;
          text-align: center;
          border: 1px solid $gray;
          color: $gray;
          background-color: white;
          border-radius: 4px;

          &:hover {
            background-color: $gray;
            color: $white;
            cursor: pointer;
          }

          &.active {
            color: white;
            background-color: #800020;
          }
        }
      }
    }
  }
}
