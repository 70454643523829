app-page-basket {
  display: flex;
  flex-flow: column;
  height: 100%;
  flex: 1 0 auto;

  .container {
    flex: 1 0 auto;
    border-bottom: 6px solid $white;
  }

  font-family: $font-family-alt;
  span {
    font-family: $font-family-alt;
  }

  .main-container {
    .movie-preview-container {
      width: 17.5%;
      margin: 0 15px;
    }

    .sidebar-header, .sidebar-body {
      padding: 16px 22px 16px 16px !important
    }

    .sidebar-header {
      font-size: 14px;
      font-weight: 500;
    }

    .sidebar-body {
      font-size: 12px;
      font-weight: 400;
    }
  }

  @media (max-width: 992px) {
    .container {
      max-width: 100%;

      .main-container {
        margin: 0;
      }
    }
  }
}
