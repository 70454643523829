app-payment-provider-payu-component,
app-payment-provider-conotoxia-component {
  input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid lightgray;
    height: 40px;
  }

  .pay-method-container {
    padding: 0 10px 0 10px;

    .pay-method-panel {
      border: none;
      padding: 1rem;
      margin: 0 0 5px 0;
      border-radius: 5px;

      .panel-head {
        top: 10px;
        height: 25px;
      }

      .label {
        font-weight: 600;
        font-size: 0.875rem;
        text-transform: uppercase;
        margin: 0;
      }

      .image {
        position: relative;
        display: inline-flex;

        img {
          position: relative;
          height: 25px !important;
          width: auto;
        }
      }
    }

    @media (max-width: 576px) {
      .pay-method-panel {
      }
    }

    .card-container {
      display: flex;

      .active {
        &:hover {
          cursor: pointer;
        }
      }

      .las-vegas {
        color: #fff;

        --border-size: 3px;
        --border-angle: 0turn;
        width: 60vmin;
        height: 50vmin;
        background-image: conic-gradient(from var(--border-angle), #213, #112 50%, #213), conic-gradient(from var(--border-angle), transparent 20%, #08f, #f03);
        background-size: calc(100% - (var(--border-size) * 2)) calc(100% - (var(--border-size) * 2)), cover;
        background-position: center center;
        background-repeat: no-repeat;

        animation: bg-spin 3s linear infinite;
        @keyframes bg-spin {
          to {
            --border-angle: 1turn;
          }
        }

        &:hover {
          animation-play-state: paused;
        }
      }

      .rainbow {
        position: relative;
        z-index: 0;
        width: 400px;
        height: 300px;
        border-radius: 10px;
        overflow: hidden;
        padding: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: sans-serif;
        font-weight: bold;

        &::before {
          content: "";
          position: absolute;
          z-index: -2;
          left: -50%;
          top: -50%;
          width: 200%;
          height: 200%;
          background-color: #399953;
          background-repeat: no-repeat;
          background-size: 50% 50%, 50% 50%;
          background-position: 0 0, 100% 0, 100% 100%, 0 100%;
          background-image: linear-gradient(#399953, #399953), linear-gradient(#fbb300, #fbb300), linear-gradient(#d53e33, #d53e33),
            linear-gradient(#377af5, #377af5);
          animation: rotate 4s linear infinite;
        }

        &::after {
          content: "";
          position: absolute;
          z-index: -1;
          left: 6px;
          top: 6px;
          width: calc(100% - 12px);
          height: calc(100% - 12px);
          background: white;
          border-radius: 5px;
        }
      }

      .card {
        width: 100%;
        height: auto;
        background-color: white;
        border: 1px solid lightgray;
        padding: 1rem;

        img {
          position: absolute;
          bottom: 0.5rem;
          right: 0.5rem;

          width: 80px;
        }

        p {
          margin-bottom: 0.2rem;
        }
      }

      .card-selected {
        border: 1px solid lightgray !important;
        box-shadow: 0 0 3px gray, 0 0 5px lightgray;

        //box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.4);
      }
    }
    .card-form {
      opacity: 1;
      transition: height 0.7s, opacity 0;

      &.hidden {
        height: 0;
        opacity: 0;
        transition: height 0.7s, opacity 0.2s;
      }
    }
    .pay-by-link-container {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;

      .pay-by-link {
        display: inline-block;
        text-align: center;
        padding: 2px;
        border: 1px solid transparent;
        cursor: pointer;

        &:hover {
          //border: 1px solid lightgray !important;
          box-shadow: 0 0 3px gray, 0 0 5px lightgray;
        }

        img {
          height: 30px !important;
          width: auto;
        }
      }

      .selected {
        border: 1px solid lightgray !important;
        box-shadow: 0 0 3px gray, 0 0 5px lightgray;
      }
    }

    .card-pay-method-container {
      order: 3;
    }

    .blick-pay-method-container {
      order: 2;
    }

    .link-pay-method-container {
      order: 4;
    }
    .google-pay-method-container {
      order: 1;
    }
    .apple-pay-method-container {
      order: 1;
    }
  }
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}
