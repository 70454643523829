app-page-payment {
  display: flex;
  flex-flow: column;
  height: 100%;
  flex: 1 0 auto;

  .container {
    flex: 1 0 auto;
    border-bottom: 6px solid $white;
  }

  app-popup {
    .body {
      height: 70% !important;
    }
    .seat-popup {
      text-align: center;
      display: flex;
      flex-direction: column;
      height: 80%;
      .popup-ticket-type {
        display: block;
        color: $primary-color;
        font-size: 1.8rem;
      }

      .popup-seat-type {
        display: block;
      }

      .popup-close-button {
        margin-top: auto;
      }
    }
  }
  .selectedMethod {
    background-color: $white !important;
  }

  .infoAbove {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.2rem;
    text-align: center;
  }

  @media (min-width: 1100px) {
    .fee-agreement-block {
      position: relative;
      bottom: 30px;
    }
  }

  .hrline-block {
    border: none !important;
  }

  .payment-block {
    transition: all 0.3s ease-out 0s;
    .pay-method-container {
      display: flex;
      justify-content: center;
    }
  }

  .pay-button {
    background-color: #0062cc;
  }

  iframe {
    overflow: hidden !important;
  }

  .checkmark,
  .radio-container input:checked ~ .checkmark {
    border-color: $black-2;
  }

  .radio-container .checkmark:after {
    background-color: $black-2;
    top: 5px;
    left: 5px;
  }

  font-family: $font-family-alt;
  span {
    font-family: $font-family-alt;
  }

  .form-control,
  .form-control:focus {
    font-family: $font-family-alt;
  }

  .main-container {
    .movie-preview-container {
      width: 17.5%;
      margin: 0 15px;
    }

    .sidebar-header {
      font-size: 14px;
      font-weight: 500;
    }

    .sidebar-body {
      font-size: 12px;
      font-weight: 400;
    }
  }

  @media (max-width: 992px) {
    .container {
      max-width: 100%;
    }
  }
}

.fee-agreement-block {
  .fee-agreement-label {
    font-weight: bold;
  }
}

.payment-box {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.2s linear;

  &.inactive {
    opacity: 0;
    visibility: hidden;
  }
}
